import React, {useState} from 'react';
import './Home.scss'

export const HomeComponent = () => {
    const [hoverText, setHoverText] = useState("");
    const [showFirstButtonCard, setShowFirstButtonCard] = useState(false);

    const chronografDescription = "Accedi alla dashboard personalizzata di Chronograf per monitorare e gestire i tuoi dati";
    const onClick = () => {
        window.location.href = 'https://samothrace-stage.alfameg.it/chronograf';
    }
    /* const onClick = () => {
        fetch('https://samothrace-stage.alfameg.it/chronograf', {
            method: 'GET',
            headers: {
                'chronosecret': 'valore1'
            },
        }).catch(error => {
                console.error('Errore nella richiesta:', error);
            });
    } */

    return (
        <>
            {showFirstButtonCard && ( // Condizione per mostrare o nascondere il primo button-card-body
                <div className="button-card-body">
                    <button className="card-button">
                        Gestione Utenti
                    </button>
                    <button type={"button"} onClick={onClick} className="card-button">
                        Chronograf
                    </button>
                </div>
            )}
            <div className="button-card-body">
                <div className="chronograf-logo-card"></div>
                <button className="card-button" onClick={onClick}>
                    Chronograf
                </button>
                <text>
                    {chronografDescription}
                </text>
                {/*<div>
                    <iframe
                        src="https://samothrace.alfameg.it/"
                        title="Embedded Page"
                        width="100"
                        height="100"
                        style={{border: "1px solid black"}}
                    ></iframe>
                </div>*/}
            </div>
        </>
    );
};
